import React from 'react'
import PropTypes from 'prop-types'
import { useDealership } from 'DealershipContext'
import frazerpayCfg from 'configs/frazerpay'
import useApi from 'components/UseApi'
import LabeledInput from 'components/LabeledInput'
import Form from 'components/Form'
import Heading from 'components/Heading'
import styles from './NewFrazerPayPaymentMethodForm.module.css'

/**
 * Form used to finalize creation of a new payment method using FrazerPay (provider-supplied form is mounted in an iFrame).
 */
const NewFrazerPayPaymentMethodForm = ({ onSuccess, onCancel }) => {
    const { dealership } = useDealership()

    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth() + 1

    const [token, setToken] = React.useState('')
    const [nickname, setNickname] = React.useState('')
    const [ownerIsBusiness, setOwnerIsBusiness] = React.useState(false)
    const [kind, setKind] = React.useState('credit')
    const [ownerFirstName, setOwnerFirstName] = React.useState('')
    const [ownerLastName, setOwnerLastName] = React.useState('')
    const [ownerMiddleName, setOwnerMiddleName] = React.useState('')
    const [street, setStreet] = React.useState('')
    const [street2, setStreet2] = React.useState('')
    const [city, setCity] = React.useState('')
    const [state, setState] = React.useState('')
    const [zipCode, setZipCode] = React.useState('')
    const [expirationYear, setExpirationYear] = React.useState()
    const [expirationMonth, setExpirationMonth] = React.useState()
    const [cvv, setCvv] = React.useState('')
    const [errorMessage, setErrorMessage] = React.useState('')

    const [createPaymentMethod, doCreatePaymentMethod] = useApi()

    const doCreatePaymentMethodCfg = {
        method: 'post',
        endpoint: '/payment-methods/frazerpay',
        body: {
            dealership_id: dealership.id,
            token: token,
            nickname: nickname,
            street: street,
            street2: street2,
            city: city,
            state: state,
            owner_is_business: ownerIsBusiness,
            kind: kind,
            owner_first_name: ownerFirstName,
            owner_last_name: ownerLastName,
            owner_middle_name: ownerMiddleName,
            zip_code: zipCode,
            expiration_year: expirationYear,
            expiration_month: expirationMonth,
            cvv: cvv
        }
    }

    // Define possible expiration years based on current year, and include a
    // zero value to require a manual selection (no default).
    let expirationYears = ['']
    for (let i = 0; i <= 20; i++) {
        // use i for current position and the amount to add to
        // the current year
        expirationYears[i + 1] = currentYear + i
    }

    // Function to handle form submission.
    const handleSubmit = () => {
        // Require unexpired cards, but only validate when kind is set to 'credit'
        if (kind === 'credit') {
            if (
                expirationYear === currentYear &&
                expirationMonth < currentMonth
            ) {
                setErrorMessage(
                    'The entered card is expired. Please enter a card that is not expired.'
                )
                return
            }
        }

        // We need to check if we have a tokenized card number manually, since
        // the value is received from an iframe message, not a form input.
        if (token === '') {
            if (kind === 'credit') {
                setErrorMessage(
                    'The entered card number does not appear to be valid. Please try again, and enter only digits for the card number.'
                )
            } else {
                setErrorMessage(
                    'The entered routing/account number does not appear to be valid. Please try again, and enter only your bank routing number followed by a slash (/) and then your account number. For example: 123456789/1234123412341234'
                )
            }

            return
        }

        doCreatePaymentMethod(doCreatePaymentMethodCfg)
    }

    // Hook to retrieve token from FrazerPay iframe
    React.useEffect(() => {
        const msgListener = (event) => {
            // Try to extract our token from the JSON payload.
            try {
                let token = JSON.parse(event.data)
                setToken(token.message)
            } catch (e) {
                // Event is for some other message, discard it.
                return
            }
        }

        window.addEventListener('message', msgListener)

        return () => {
            window.removeEventListener('message', msgListener)
        }
    }, [])

    // Hook to handle api response
    React.useEffect(() => {
        if (createPaymentMethod.loading) {
            return
        }

        if (createPaymentMethod.error) {
            setErrorMessage(createPaymentMethod.error.message)
            return
        }

        if (createPaymentMethod.data) {
            onSuccess()
        }
    }, [createPaymentMethod, onSuccess])

    if (errorMessage) {
        return (
            <>
                <Heading size="md">Unsuccessful</Heading>
                <p>{errorMessage}</p>
                <button type="button" onClick={() => onCancel()}>
                    OK
                </button>
            </>
        )
    }

    // Define the iframe src.
    let iframeSrc = new URL(
        `https://${frazerpayCfg.iframeSrcSubdomain}.cardconnect.com/itoke/ajax-tokenizer.html`
    ).href

    // Manually encode the query string pieces that cardconnect freaks out about (these are not normally encoded by new URL).
    iframeSrc += '?formatinput=true&css=input{width:260px;padding:8px}&fullmobilekeyboard=true&tokenizewheninactive=true&inactivityto=1000'
        .replace(/{/g, '%7B')
        .replace(/}/g, '%7D')
        .replace(/:/g, '%3A')
        .replace(/\./g, '%2E')
        .replace(/;/g, '%3B')

    // Define form disabled state.
    const disabled = createPaymentMethod.loading || !!createPaymentMethod.data

    return (
        <>
            <Form
                disabled={disabled}
                onSubmit={() => {
                    handleSubmit()
                }}
                onCancel={() => onCancel()}
                coloredButtons={true}
            >
                {dealership.allow_ach && (
                    <fieldset disabled={disabled}>
                        <legend>Type</legend>
                        <LabeledInput
                            label="Credit Card"
                            type="radio"
                            name="payment-method-type"
                            required
                            checked={kind === 'credit'}
                            onChange={() => {
                                setKind('credit')
                            }}
                            key="credit"
                        />

                        <LabeledInput
                            label="ACH"
                            type="radio"
                            name="payment-method-type"
                            required
                            checked={kind !== 'credit'}
                            onChange={() => {
                                setKind('checking')
                            }}
                            key="ach"
                        />
                    </fieldset>
                )}
                {kind === 'credit' ? (
                    <fieldset disabled={disabled} className={styles.fieldset}>
                        <LabeledInput
                            label="Card Number (digits only)"
                            type="hidden"
                        />
                        <iframe
                            title="FrazerPayForm"
                            src={iframeSrc}
                            frameBorder="0"
                            scrolling="no"
                            height="50"
                            width="300"
                        ></iframe>
                        <LabeledInput
                            label="Card Nickname"
                            type="text"
                            placeholder="My Card"
                            maxLength="30"
                            value={nickname}
                            onChange={(e) => {
                                setNickname(e.target.value)
                            }}
                        />
                        <LabeledInput
                            label="Corporate card"
                            type="checkbox"
                            className={styles.checkbox}
                            checked={ownerIsBusiness}
                            onChange={(e) => {
                                setOwnerIsBusiness(e.target.checked)
                            }}
                        />
                        <LabeledInput
                            label="Expiration Month"
                            type="select"
                            value={expirationMonth}
                            onChange={(e) => {
                                setExpirationMonth(parseInt(e.target.value))
                            }}
                            required
                        >
                            <option key="expiration-month-0" value="" />
                            <option key="expiration-month-1" value={1}>
                                January
                            </option>
                            <option key="expiration-month-2" value={2}>
                                February
                            </option>
                            <option key="expiration-month-3" value={3}>
                                March
                            </option>
                            <option key="expiration-month-4" value={4}>
                                April
                            </option>
                            <option key="expiration-month-5" value={5}>
                                May
                            </option>
                            <option key="expiration-month-6" value={6}>
                                June
                            </option>
                            <option key="expiration-month-7" value={7}>
                                July
                            </option>
                            <option key="expiration-month-8" value={8}>
                                August
                            </option>
                            <option key="expiration-month-9" value={9}>
                                September
                            </option>
                            <option key="expiration-month-10" value={10}>
                                October
                            </option>
                            <option key="expiration-month-11" value={11}>
                                November
                            </option>
                            <option key="expiration-month-12" value={12}>
                                December
                            </option>
                        </LabeledInput>
                        <LabeledInput
                            label="Expiration Year"
                            type="select"
                            value={expirationYear}
                            onChange={(e) => {
                                setExpirationYear(parseInt(e.target.value))
                            }}
                            required
                        >
                            {expirationYears.map((ey) => {
                                return (
                                    <option
                                        key={`expiration-year-${ey}`}
                                        value={ey}
                                    >
                                        {ey}
                                    </option>
                                )
                            })}
                        </LabeledInput>
                        <LabeledInput
                            label={
                                <details>
                                    <summary>
                                        <abbr title="Card Verification Value">
                                            CVV
                                        </abbr>
                                    </summary>
                                    <p className={styles.cvvDetails}>
                                        CVV is usually a 3 or 4 digit number
                                        found on the back of your credit card.
                                    </p>
                                </details>
                            }
                            type="text"
                            value={cvv}
                            onChange={(e) => {
                                setCvv(e.target.value)
                            }}
                            required
                        />
                        <LabeledInput
                            label="Street Address"
                            type="text"
                            value={street}
                            onChange={(e) => {
                                setStreet(e.target.value)
                            }}
                            required
                        />
                        <LabeledInput
                            label="Street Address 2"
                            type="text"
                            value={street2}
                            onChange={(e) => {
                                setStreet2(e.target.value)
                            }}
                        />
                        <LabeledInput
                            label="City"
                            type="text"
                            value={city}
                            onChange={(e) => {
                                setCity(e.target.value)
                            }}
                            required
                        />
                        <LabeledInput
                            label="State"
                            type="select"
                            value={state}
                            onChange={(e) => {
                                setState(e.target.value)
                            }}
                            required
                        >
                            <option key={'empty'} value={''}></option>
                            {Object.keys(usStates).map((k) => (
                                <option key={k} value={k}>
                                    {usStates[k]}
                                </option>
                            ))}
                        </LabeledInput>
                        <LabeledInput
                            label="Zip Code"
                            type="text"
                            value={zipCode}
                            onChange={(e) => {
                                setZipCode(e.target.value)
                            }}
                            required
                        />
                        <LabeledInput
                            label="First Name"
                            type="text"
                            value={ownerFirstName}
                            onChange={(e) => {
                                setOwnerFirstName(e.target.value)
                            }}
                        />
                        <LabeledInput
                            label="Middle Name"
                            type="text"
                            value={ownerMiddleName}
                            onChange={(e) => {
                                setOwnerMiddleName(e.target.value)
                            }}
                        />
                        <LabeledInput
                            label="Last Name"
                            type="text"
                            value={ownerLastName}
                            onChange={(e) => {
                                setOwnerLastName(e.target.value)
                            }}
                            required
                        />
                    </fieldset>
                ) : (
                    <fieldset disabled={disabled} className={styles.fieldset}>
                        <LabeledInput
                            label={
                                <details>
                                    <summary>Routing/Account Number</summary>
                                    <p className={styles.cvvDetails}>
                                        ACH account number must be entered in
                                        the following format: routing number /
                                        account number (with no spaces).
                                    </p>
                                </details>
                            }
                            type="hidden"
                        />
                        <iframe
                            title="FrazerPayForm"
                            src={iframeSrc}
                            frameBorder="0"
                            scrolling="no"
                            height="50"
                            width="300"
                        ></iframe>
                        <LabeledInput
                            label="Account Type"
                            type="select"
                            value={kind}
                            onChange={(e) => {
                                setKind(e.target.value)
                            }}
                            required
                        >
                            <option key="checking" value="checking">
                                Checking
                            </option>
                            <option key="savings" value="savings">
                                Savings
                            </option>
                        </LabeledInput>
                        <LabeledInput
                            label="Account Nickname"
                            type="text"
                            placeholder="My Account"
                            maxLength="30"
                            value={nickname}
                            onChange={(e) => {
                                setNickname(e.target.value)
                            }}
                        />
                        <LabeledInput
                            label="Corporate Account"
                            type="checkbox"
                            className={styles.checkbox}
                            checked={ownerIsBusiness}
                            onChange={(e) => {
                                setOwnerIsBusiness(e.target.checked)
                            }}
                        />
                        <LabeledInput
                            label="Street Address"
                            type="text"
                            value={street}
                            onChange={(e) => {
                                setStreet(e.target.value)
                            }}
                            required
                        />
                        <LabeledInput
                            label="Street Address 2"
                            type="text"
                            value={street2}
                            onChange={(e) => {
                                setStreet2(e.target.value)
                            }}
                        />
                        <LabeledInput
                            label="City"
                            type="text"
                            value={city}
                            onChange={(e) => {
                                setCity(e.target.value)
                            }}
                            required
                        />
                        <LabeledInput
                            label="State"
                            type="select"
                            value={state}
                            onChange={(e) => {
                                setState(e.target.value)
                            }}
                            required
                        >
                            <option key={'empty'} value={''}></option>
                            {Object.keys(usStates).map((k) => (
                                <option key={k} value={k}>
                                    {usStates[k]}
                                </option>
                            ))}
                        </LabeledInput>
                        <LabeledInput
                            label="Zip Code"
                            type="text"
                            value={zipCode}
                            onChange={(e) => {
                                setZipCode(e.target.value)
                            }}
                            required
                        />
                        <LabeledInput
                            label="First Name"
                            type="text"
                            value={ownerFirstName}
                            onChange={(e) => {
                                setOwnerFirstName(e.target.value)
                            }}
                        />
                        <LabeledInput
                            label="Middle Name"
                            type="text"
                            value={ownerMiddleName}
                            onChange={(e) => {
                                setOwnerMiddleName(e.target.value)
                            }}
                        />
                        <LabeledInput
                            label="Last Name"
                            type="text"
                            value={ownerLastName}
                            onChange={(e) => {
                                setOwnerLastName(e.target.value)
                            }}
                            required
                        />
                    </fieldset>
                )}
            </Form>
        </>
    )
}

NewFrazerPayPaymentMethodForm.propTypes = {
    onSuccess: PropTypes.func,
    onCancel: PropTypes.func
}

export default NewFrazerPayPaymentMethodForm

const usStates = {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DC: 'District of Columbia',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming'
}
